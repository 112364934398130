/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWarehouses = /* GraphQL */ `query GetWarehouses {
  getWarehouses {
    items {
      warehouse_id
      ambient_nyr_pallets
      business_type
      chilled_nyr_pallets
      city
      country_code
      frozen_nyr_pallets
      geography
      is_hub
      region_id
      store_id
      time_zone
      wms
      marketplace_id
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getShipmentSummaries = /* GraphQL */ `query GetShipmentSummaries(
  $warehouseId: String!
  $minAppointmentTime: String!
  $maxAppointmentTime: String!
  $nextToken: String
) {
  getShipmentSummaries(
    warehouseId: $warehouseId
    minAppointmentTime: $minAppointmentTime
    maxAppointmentTime: $maxAppointmentTime
    nextToken: $nextToken
  ) {
    items {
      orderId
      recordType
      warehouseId
      distributorId
      expectedQuantityScip
      firstReceiveTime
      dockReceiveHistory
      expectedQtySource
      firstDockReceiveTime
      firstDockReceiveBy
      lastDockReceiveTime
      lastDockReceiveBy
      poItemSummary
      associatedAppointments
      firstAppointmentTime
      firstAppointmentSource
      totalAppointmentCount
      departureTime
      srcWarehouseId
      estimatedArrivalTime
      shipmentCarrierId
      status
      tempZoneDetailed
      suppressionHistory
      nextAppointmentTime
      handoffWindowStartDateUTC
      handoffWindowEndDateUTC
      orderCondition
      vrCheckinCompletionTime
      vrPlannedCheckinTime
      vrStopStatus
      manifestSummary
      palletIds
      eventSummary
      freightEstimatedArrivalTime
      freightActualArrivalTime
      freightArrivalStatus
      transferId
      vehicleRunId
      vrEstimatedTimeOfArrival
      distributorName
      ihmEventType
      ihmActualReceiveType
      shipmentValidity
      stockSla
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getFABForecasts = /* GraphQL */ `query GetFABForecasts(
  $warehouseId: String!
  $minAppointmentTime: String!
  $maxAppointmentTime: String!
  $nextToken: String
) {
  getFABForecasts(
    warehouseId: $warehouseId
    minAppointmentTime: $minAppointmentTime
    maxAppointmentTime: $maxAppointmentTime
    nextToken: $nextToken
  ) {
    items {
      warehouseId
      orderId
      distributorIdScip
      expectedArrivalTimeScip
      expectedQtySourceScip
      expectedQuantityScip
      firstAppointmentSource
      firstAppointmentTime
      lastUpdatedTimeScip
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getTSIForecasts = /* GraphQL */ `query GetTSIForecasts(
  $warehouseId: String!
  $minAppointmentTime: String!
  $maxAppointmentTime: String!
  $nextToken: String
) {
  getTSIForecasts(
    warehouseId: $warehouseId
    minAppointmentTime: $minAppointmentTime
    maxAppointmentTime: $maxAppointmentTime
    nextToken: $nextToken
  ) {
    items {
      warehouseId
      vehicleRunId
      amazonShipmentReferenceId
      firstDockReceiveTime
      expectedArrivalTimeScip
      expectedQuantityScip
      firstAppointmentSource
      firstAppointmentTime
      forecastTypeScip
      lastUpdatedTimeScip
      srcWarehouseId
      vehicleRunIdList
      vehicleRunIds
      vrCheckinCompletionTime
      vrPlannedCheckinTime
      vrStopStatus
      transferId
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getIOL = /* GraphQL */ `query GetIOL(
  $warehouseId: String!
  $dwellTimeThreshold: String!
  $nextToken: String
  $snapshotTime: String
) {
  getIOL(
    warehouseId: $warehouseId
    dwellTimeThreshold: $dwellTimeThreshold
    nextToken: $nextToken
    snapshotTime: $snapshotTime
  ) {
    items {
      location
      fcsku
      asin
      asinTitle
      cost
      expirationQuantityMap
      firstActionTime
      fnsku
      lastActionTime
      locationId
      locationType
      quantity
      receivedTime
      snapshotTime
      shrinkDateMap
      tempZone
      warehouseId
      warehouseId_LocationType
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getFalconTroutDetails = /* GraphQL */ `query GetFalconTroutDetails($po: String!, $nextToken: String) {
  getFalconTroutDetails(po: $po, nextToken: $nextToken) {
    items {
      orderId
      recordType
      warehouseId
      distributorId
      tempZone
      asinTitle
      asin
      quantityCancelled
      quantityConfirmed
      quantityExpected
      quantityReceived
      quantitySubmitted
      wmsQuantityReceived
      wmsReceiveHistory
      firstReceiveTime
      firstDockReceiveTime
      firstDockReceiveBy
      lastDockReceiveTime
      lastDockReceiveBy
      associatedShipItemIds
      associatedAppointments
      nextAppointmentTime
      firstAppointmentTime
      fcSku
      handoffWindowStartDateUTC
      handoffWindowEndDateUTC
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getNYRDetails = /* GraphQL */ `query GetNYRDetails($po: String!, $tempZone: String!, $nextToken: String) {
  getNYRDetails(po: $po, tempZone: $tempZone, nextToken: $nextToken) {
    items {
      asin
      asinTitle
      isCriticalAsin
      quantityConfirmed
      quantityReceived
      tempZone
      asrsEligible
      criticalEvent
      wmsQuantityReceived
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getNYSDetails = /* GraphQL */ `query GetNYSDetails(
  $manifestId: String!
  $tempZone: String!
  $nextToken: String
) {
  getNYSDetails(
    manifestId: $manifestId
    tempZone: $tempZone
    nextToken: $nextToken
  ) {
    items {
      asin
      asinTitle
      isCriticalAsin
      quantitySerenity
      wmsQuantityReceived
      tempZone
      toteId
      wmsQuantityReceivedStow
      asrsEligible
      manifestQuantity
      createdBySource
      nysQuantityWMS
      criticalEvent
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getOCRVendors = /* GraphQL */ `query GetOCRVendors($regionId: Int, $nextToken: String) {
  getOCRVendors(regionId: $regionId, nextToken: $nextToken) {
    items {
      regionId
      distributorId
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getPastShipments = /* GraphQL */ `query GetPastShipments(
  $warehouseId: String!
  $minAppointmentTime: String!
  $nextToken: String
) {
  getPastShipments(
    warehouseId: $warehouseId
    minAppointmentTime: $minAppointmentTime
    nextToken: $nextToken
  ) {
    items {
      orderId
      recordType
      warehouseId
      distributorId
      expectedQuantityScip
      firstReceiveTime
      firstDockReceiveTime
      firstDockReceiveBy
      lastDockReceiveTime
      lastDockReceiveBy
      poItemSummary
      associatedAppointments
      firstAppointmentTime
      totalAppointmentCount
      srcWarehouseId
      estimatedArrivalTime
      status
      reconcileDuration
      reconcileTime
      handoffWindowStartDateUTC
      handoffWindowEndDateUTC
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getNYRSnapshots = /* GraphQL */ `query GetNYRSnapshots(
  $warehouseId: String!
  $minSnapshotTime: String!
  $nextToken: String
) {
  getNYRSnapshots(
    warehouseId: $warehouseId
    minSnapshotTime: $minSnapshotTime
    nextToken: $nextToken
  ) {
    items {
      warehouseId
      snapshotTime
      nyrnysSnapshot
      warehouseHour
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getSCIPSummaries = /* GraphQL */ `query GetSCIPSummaries(
  $warehouseId: String!
  $minExpectedArrivalDate: Int!
  $maxExpectedArrivalDate: Int!
  $nextToken: String
) {
  getSCIPSummaries(
    warehouseId: $warehouseId
    minExpectedArrivalDate: $minExpectedArrivalDate
    maxExpectedArrivalDate: $maxExpectedArrivalDate
    nextToken: $nextToken
  ) {
    items {
      warehouseId
      expectedArrivalDate
      expectedArrivalDateScip
      expectedQuantityScip
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getDCs = /* GraphQL */ `query GetDCs($regionId: Int!) {
  getDCs(regionId: $regionId) {
    items {
      warehouse_id
      ambient_nyr_pallets
      business_type
      chilled_nyr_pallets
      city
      country_code
      frozen_nyr_pallets
      geography
      is_hub
      region_id
      store_id
      time_zone
      wms
      marketplace_id
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getAFTLiteIOL = /* GraphQL */ `query GetAFTLiteIOL(
  $warehouseId: String!
  $dwellTimeThreshold: String!
  $nextToken: String
) {
  getAFTLiteIOL(
    warehouseId: $warehouseId
    dwellTimeThreshold: $dwellTimeThreshold
    nextToken: $nextToken
  ) {
    items {
      location
      fcsku
      asin
      asinTitle
      cost
      expirationQuantityMap
      firstActionTime
      fnsku
      lastActionTime
      locationId
      locationType
      quantity
      receivedTime
      snapshotTime
      shrinkDateMap
      tempZone
      warehouseId
      warehouseId_LocationType
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getInstockRiskData = /* GraphQL */ `query GetInstockRisk(
  $warehouseId: String!
  $recordUpdatedTime: String!
  $nextToken: String
) {
  getInstockRisk(
    warehouseId: $warehouseId
    recordUpdatedTime: $recordUpdatedTime
    nextToken: $nextToken
  ) {
    items {
      warehouseId
      asin
      orderId
      alarm
      demand
      onhand_inventory
      tempZone
      tempZoneDetailed
      asinTitle
      recordUpdatedTime
      quantityConfirmed
      snapshot_time_local
      weight
      total_weight
      oos_asin_category
      palletId
      noos
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getInstockVBIData = /* GraphQL */ `query GetInstockVBI($warehouseId: String!, $nextToken: String) {
  getInstockVBI(warehouseId: $warehouseId, nextToken: $nextToken) {
    items {
      warehouse_id
      combined_datetime
      snapshot_day
      snapshot_hour
      vbi_weight_pcnt
      record_updated_datetime
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getInstockUpdatedDate = /* GraphQL */ `query GetInstockUpdatedDate(
  $orderId: String!
  $warehouse_asin: String
  $nextToken: String
) {
  getInstockUpdatedDate(
    orderId: $orderId
    warehouse_asin: $warehouse_asin
    nextToken: $nextToken
  ) {
    items {
      orderId
      warehouse_asin
      record_expiration_time
      recordUpdatedTime
      snapshot_date
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getSCIPLatestForecast = /* GraphQL */ `query GetSCIPLatestForecast {
  getSCIPLatestForecast {
    items {
      warehouse_id_order_id_temp_zone
      expected_arrival_time
      expected_qty
      ib_flow
      load_id
      origin_fc
      temp_zone
      time_zone
      warehouse_id
      snapshot_time
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getSCIPForecasts = /* GraphQL */ `query GetSCIPForecasts(
  $warehouseId: String!
  $minAppointmentTime: String!
  $maxAppointmentTime: String!
  $latestSnapShotTime: String!
  $nextToken: String
) {
  getSCIPForecasts(
    warehouseId: $warehouseId
    minAppointmentTime: $minAppointmentTime
    maxAppointmentTime: $maxAppointmentTime
    latestSnapShotTime: $latestSnapShotTime
    nextToken: $nextToken
  ) {
    items {
      warehouse_id_order_id_temp_zone
      expected_arrival_time
      expected_qty
      ib_flow
      load_id
      origin_fc
      temp_zone
      time_zone
      warehouse_id
      snapshot_time
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getIOLSnapshot = /* GraphQL */ `query GetIOLSnapshot(
  $warehouseId: String!
  $snapshotDate: String!
  $nextToken: String
) {
  getIOLSnapshot(
    warehouseId: $warehouseId
    snapshotDate: $snapshotDate
    nextToken: $nextToken
  ) {
    items {
      warehouse_id_location_id_asin
      snapshot_date
      asin
      expired_quantity
      first_action_time
      item_name
      list_price
      location_id
      quantity
      temp_zone
      warehouse_id
      record_updated_datetime
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getShipmentSearchItems = /* GraphQL */ `query GetShipmentSearchItems(
  $warehouseId: String!
  $inputId: String!
  $minAppointmentTime: String!
  $maxAppointmentTime: String!
) {
  getShipmentSearchItems(
    warehouseId: $warehouseId
    inputId: $inputId
    minAppointmentTime: $minAppointmentTime
    maxAppointmentTime: $maxAppointmentTime
  ) {
    items {
      orderId
      itemFirstAppointmentTime
      asin
      asinTitle
      isCriticalAsin
      quantityConfirmed
      quantityReceived
      quantitySerenity
      wmsQuantityReceived
      tempZone
      wmsQuantityReceivedStow
      manifestQuantity
      createdBySource
      nysQuantityWMS
      criticalEvent
      palletId
      containerId
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getSingleShipmentSummary = /* GraphQL */ `query GetSingleShipmentSummary($orderId: String!, $recordType: String!) {
  getSingleShipmentSummary(orderId: $orderId, recordType: $recordType) {
    orderId
    recordType
    warehouseId
    distributorId
    expectedQuantityScip
    firstReceiveTime
    dockReceiveHistory
    expectedQtySource
    firstDockReceiveTime
    firstDockReceiveBy
    lastDockReceiveTime
    lastDockReceiveBy
    poItemSummary
    associatedAppointments
    firstAppointmentTime
    firstAppointmentSource
    totalAppointmentCount
    departureTime
    srcWarehouseId
    estimatedArrivalTime
    shipmentCarrierId
    status
    tempZoneDetailed
    suppressionHistory
    nextAppointmentTime
    handoffWindowStartDateUTC
    handoffWindowEndDateUTC
    orderCondition
    vrCheckinCompletionTime
    vrPlannedCheckinTime
    vrStopStatus
    manifestSummary
    palletIds
    eventSummary
    freightEstimatedArrivalTime
    freightActualArrivalTime
    freightArrivalStatus
    transferId
    vehicleRunId
    vrEstimatedTimeOfArrival
    distributorName
    ihmEventType
    ihmActualReceiveType
    shipmentValidity
    stockSla
    __typename
  }
}
`;
export const getPalletId = /* GraphQL */ `query GetPalletId($palletId: String!) {
  getPalletId(palletId: $palletId) {
    items {
      orderId
      itemFirstAppointmentTime
      asin
      asinTitle
      isCriticalAsin
      quantityConfirmed
      quantityReceived
      quantitySerenity
      wmsQuantityReceived
      tempZone
      wmsQuantityReceivedStow
      manifestQuantity
      createdBySource
      nysQuantityWMS
      criticalEvent
      palletId
      containerId
      __typename
    }
    nextToken
    __typename
  }
}
`;
export const getAsinsFromBarcode = /* GraphQL */ `query GetAsinsFromBarcode($barcode: String!, $marketplace_id: Int) {
  getAsinsFromBarcode(barcode: $barcode, marketplace_id: $marketplace_id) {
    items {
      barcode
      asin
      barcode_type
      marketplace_id
      region_id
      __typename
    }
    nextToken
    __typename
  }
}
`;
