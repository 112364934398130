/**
 * A context-based hook that allows components to determine the view of the
 * "Dock Received" page. The API is the same as useContext.
 */

import React, { useState, useCallback, useContext, createContext } from "react"
import PropTypes from "prop-types"
import useLocalStorage from "./use-local-storage"
import { defaultDockReceivedTableColumns } from "../data/dock-received-table-columns"

const DockReceivedViewPreferencesContext = createContext()

export const DockReceivedViewPreferencesProvider = props => {
  const defaultSavedPreferencesConfig = new Map([
    ["stripes", true],
    ["columns", defaultDockReceivedTableColumns],
  ]);
  // Get dock-received view preferences out of local storage
  const [savedPreferences, persistPreferences] = useLocalStorage(
    "dock-received-view-preferences"
  )

  // Initialize state value falling back to the default values defined above
  const [preferences, setPreferences] = useState(
    // Revive preference map from local storage if it exists
    savedPreferences
      // add new columns to local storage if the user has outdated column structure
      ? new Map([
        ["stripes", new Map(savedPreferences).get("stripes")],
        ["columns", { ...defaultSavedPreferencesConfig.get("columns"), ...new Map(savedPreferences).get("columns") }],
      ])
      : defaultSavedPreferencesConfig
  )

  // When the user changes the preferences, persist the value to local storage
  // and update the state value
  const changePreferences = useCallback(
    values => {
      setPreferences(values)
      // Convert the values Map to an array when putting into local storage
      // so it can be serialized with JSON.stringify
      persistPreferences(Array.from(values.entries()))
    },
    [persistPreferences]
  )

  return (
    <DockReceivedViewPreferencesContext.Provider
      value={[preferences, changePreferences]}
    >
      {props.children}
    </DockReceivedViewPreferencesContext.Provider>
  )
}

DockReceivedViewPreferencesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useDockReceivedViewPreferences = () =>
  useContext(DockReceivedViewPreferencesContext)

export default useDockReceivedViewPreferences