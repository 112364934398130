import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from "prop-types";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import infoFilledTokens from "@amzn/meridian-tokens/base/icon/info"
import Popover, { PopoverHeader } from "@amzn/meridian/popover"
import Text from "@amzn/meridian/text"
import Button from "@amzn/meridian/button";
import Toggle from "@amzn/meridian/toggle";

const CustomInput = React.forwardRef<HTMLInputElement, {
    value: string;
    onChange: (value: string) => void;
    onSubmit: () => void;
    onClear: () => void;
    disabled: boolean;
    placeholder: string;
    width: number;
}>(({ value, onChange, onSubmit, onClear, disabled, placeholder, width }, ref) => {
    return (
        <div style={{ display: 'inline-flex', width: `${width}px`, position: 'relative' }}>
            <input
                ref={ref}
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        onSubmit();
                    }
                }}
                disabled={disabled}
                placeholder={placeholder}
                style={{
                    width: '100%',
                    padding: '8px',
                    fontSize: '16px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                }}
            />
            <div style={{ marginLeft: '10px' }}>
                <Button onClick={onSubmit} disabled={disabled}>
                    Search
                </Button>
            </div>
            <div style={{ marginLeft: '10px' }}>
                <Button onClick={onClear} disabled={disabled}>
                    Clear
                </Button>
            </div>
        </div>
    );
});

export const ShipmentSearchBar = props => {
    const {searchInput, setSearchInput, getSearchData, setSearchResults} = props;
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [localInputValue, setLocalInputValue] = useState(searchInput);
    const [autoClear, setAutoClear] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const isScanning = useRef<boolean>(false);
    const lastInputTimeRef = useRef<number>(0);
    const inputBufferRef = useRef<string>('');
    const searchInProgressRef = useRef<boolean>(false);

    const handleSearch = useCallback(async () => {
        if (searchInProgressRef.current) {
            return;
        }

        const searchValue = inputBufferRef.current || localInputValue;
        setLocalInputValue(searchValue);
        setSearchInput(searchValue);
        setDisabled(true);
        searchInProgressRef.current = true;

        try {
            await getSearchData(searchValue);
        } catch (error) {
            setSearchResults([]);
        } finally {
            setDisabled(false);
            isScanning.current = false;
            searchInProgressRef.current = false;
        }

        if (autoClear) {
            setTimeout(() => {
                setLocalInputValue('');
                setSearchInput('');
                inputBufferRef.current = '';
            }, 500);
        }
    }, [localInputValue, getSearchData, setSearchInput, setSearchResults, autoClear]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const now = Date.now();
            const timeSinceLastInput = now - lastInputTimeRef.current;

            if (event.key === 'Enter') {
                event.preventDefault();
                handleSearch();
                inputBufferRef.current = '';
            } else if (event.key.length === 1) {
                if (timeSinceLastInput < 50 || isScanning.current) {
                    isScanning.current = true;
                    inputBufferRef.current += event.key;
                    setLocalInputValue(inputBufferRef.current);

                    if (inputBufferRef.current.length >= 12) {
                        handleSearch();
                        inputBufferRef.current = '';
                    }

                    event.preventDefault();
                } else {
                    isScanning.current = false;
                }
                lastInputTimeRef.current = now;
            }
        };

        window.addEventListener('keydown', handleKeyDown, true);
        return () => {
            window.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [handleSearch]);

    const handleInputChange = useCallback((value: string) => {
        if (!isScanning.current) {
            setLocalInputValue(value);
            inputBufferRef.current = value;
        }
    }, []);

    const handleClear = useCallback(() => {
        setLocalInputValue('');
        setSearchInput('');
        inputBufferRef.current = '';
        isScanning.current = false;
    }, [setSearchInput]);


    const isHTMLElement = (element: any): element is HTMLElement => {
        return element instanceof HTMLElement;
    };

    return (
        <Column>
            <Row>
                <CustomInput
                    ref={inputRef}
                    value={localInputValue}
                    onChange={handleInputChange}
                    onSubmit={handleSearch}
                    onClear={handleClear}
                    disabled={disabled}
                    placeholder="UPC, EAN, ISBN, ASIN or Pallet Id"
                    width={460}
                />
                <div style={{ marginLeft: '10px' }}>
                    <Button type="icon" onClick={() => setOpen(true)} ref={buttonRef}>
                        <Icon tokens={infoFilledTokens} />
                    </Button>
                </div>
                {isHTMLElement(buttonRef.current) && (
                    <Popover
                        anchorNode={buttonRef.current}
                        open={open}
                        onClose={() => setOpen(false)}
                        position="bottom"
                    >
                        <PopoverHeader closeLabel="Close">Search Ranges</PopoverHeader>
                        <Column>
                            <Text><strong>Incoming Shipments</strong></Text>
                            <Text>Yesterday to three days from now</Text>
                            <Text><strong>Dock Received</strong></Text>
                            <Text>Today to two weeks ago</Text>
                            <Text><strong>Pallet Id</strong></Text>
                            <Text>Today to 90 days ago</Text>
                        </Column>
                    </Popover>
                )}
            </Row>
            <div style={{ marginTop: '16px' }}>
                <Row>
                    <Text>Auto-clear input after search</Text>
                    <Toggle
                        onChange={setAutoClear}
                        checked={autoClear}
                    />
                </Row>
            </div>
        </Column>
    );
};

ShipmentSearchBar.propTypes = {
    searchInput: PropTypes.string,
    setSearchInput: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    setSearchResults: PropTypes.func.isRequired,
};

export default ShipmentSearchBar;
