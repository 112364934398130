/**
 * The action bar shown above the dock received table. Includes a search field and
 * filters to help find shipments.
 */

import React, { useRef } from "react";
import PropTypes from "prop-types";
import { TableActionBar } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Tooltip from "@amzn/meridian/tooltip";
import filterIconTokens from "@amzn/meridian-tokens/base/icon/filter";
import DockReceivedSearchField from "./dock-received-search-field";
import DockReceivedSuppressionFilter from "./dock-received-suppression-filter";
import DockReceivedViewPreferences from "./dock-received-view-preferences";
import useDockReceivedFilters from "../../hooks/use-dock-received-filters";
import usePageLayoutContentRectangle from "../../hooks/use-page-layout-content-rectangle";
import dockReceivedPropType from "../../prop-types/dock-received";
import CsvDownloader from 'react-csv-downloader';
import DCEscalationsModal from "./dc-escalations-modal";

const FilterButton = () => {
  const {
    sheet: [sheetOpen, setSheetOpen],
  } = useDockReceivedFilters()
  const buttonRef = useRef()
  return (
    <Tooltip title="Filters" position="top">
      <Button
        ref={buttonRef}
        onClick={() => setSheetOpen(!sheetOpen)}
        type="icon"
      >
        <Icon tokens={filterIconTokens}>Filters</Icon>
      </Button>
    </Tooltip>
  )
}

const DockReceivedActionBar = props => {
  const { dockReceived, totalSuppressions, totalExpectedUnits, totalReceivedUnits, totalNYR, notifications, warehouseId, dcEscalations, getDCEscalations } = props
  const Wrapper = Row;
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle()
  const mobile = contentWidth < 600

  const totalSLAs = () => {
    let notificationTotals = {};
    for (const notification of Object.keys(notifications)) {
      for (const shipment of notifications[notification]) {
        let slaTotal = notificationTotals[notification] ? notificationTotals[notification] : 0;
        slaTotal += Math.max(shipment.nyrnys, 0);
        notificationTotals[notification] = slaTotal;
      }
    }
    return notificationTotals
  };

  let { nearSLA, pastSLA } = totalSLAs();

  nearSLA = nearSLA ? nearSLA : 0;
  pastSLA = pastSLA ? pastSLA : 0;

  const csvColumns = ["loadType", "source", "loadId", "tempZone", "actualArrivalParsed", "dockReceivedParsed",
    "lastDockReceiveBy", "firstReceiveTimeParsed", "confirmedUnits", "asrsConfirmedUnits", "asrsConfirmedUnitsPercent", "receivedUnits", "asrsReceivedUnits", "nyrnys",
    "receiveStatus", "actualReceiveType", "shipmentValidity", "progress", "dwellDays", "stockSla", "timeToSLA", "sla", "suppression"
  ]

  return (
    <Wrapper widths="fill">
      <TableActionBar alignmentHorizontal="justify">
        <Row spacing="200">

          <DockReceivedSearchField mobile={mobile} />
          <FilterButton />
          <Row>
            <Heading level={6}>Total Expected:</Heading>
            <Text>{totalExpectedUnits.toLocaleString()}</Text>
            <Heading level={6}>Total Received:</Heading>
            <Text>{totalReceivedUnits.toLocaleString()}</Text>
            <Heading level={6}>Total NYR/NYS:</Heading>
            <Text>{totalNYR.toLocaleString()}</Text>
            <Heading level={6}>{"[Past SLA: "}</Heading>
            <Text>{`${pastSLA.toLocaleString()},`}</Text>
            <Heading level={6}>{" Near SLA: "}</Heading>
            <Text>{nearSLA.toLocaleString()}<strong>{"]"}</strong></Text>
          </Row>
        </Row>
        <Row spacing="200">
          <DockReceivedViewPreferences />
          <DockReceivedSuppressionFilter totalSuppressions={totalSuppressions} />
          <CsvDownloader
            datas={dockReceived.map(data => Object.fromEntries(csvColumns.map(key => [key, data[key]])))}
            filename="dock-received"
            extension=".csv"
          >
            <Button>CSV</Button>
          </CsvDownloader>
          <DCEscalationsModal
            warehouseId={warehouseId}
            dcEscalations={dcEscalations}
            getDCEscalations={getDCEscalations}
          />
        </Row>
      </TableActionBar>
    </Wrapper>
  )
}

DockReceivedActionBar.propTypes = {
  stick: PropTypes.bool.isRequired,
  dockReceived: PropTypes.arrayOf(dockReceivedPropType),
  notifications: PropTypes.object.isRequired,
  totalSuppressions: PropTypes.number.isRequired,
  totalExpectedUnits: PropTypes.number.isRequired,
  totalReceivedUnits: PropTypes.number.isRequired,
  totalNYR: PropTypes.number.isRequired,
  warehouseId: PropTypes.string.isRequired,
  dcEscalations: PropTypes.arrayOf(PropTypes.object),
  getDCEscalations: PropTypes.func
}

export default DockReceivedActionBar
